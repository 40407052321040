jQuery(document).ready(function () {
    jQuery('.bxslider').bxSlider({
		pager: false,
        controls: (jQuery('.bxslider').children().length < 2) ? false : true,
		auto: false,
		//auto: (jQuery('.bxslider').children().length < 2) ? false : true,
		touchEnabled:(jQuery('.bxslider').children().length < 2) ? false : true,
        useCSS: false
    });

    jQuery(".mobile-menu").on("click touchstart", function (event) {
        event.preventDefault();
		event.stopPropagation();
		jQuery("html").toggleClass("menu-open");
    });

   jQuery(document).on('click touchstart', function () {
        jQuery('html').removeClass('menu-open');
    });

     jQuery(".site-header .navigation").on("click touchstart", function (event) {
		event.stopPropagation();
    });

    jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){
        if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0){
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });

    jQuery(".search-bar .search-icon").click(function (event) {
	  event.stopPropagation();
	  jQuery(this).next(".search-form").slideToggle();
	});

    jQuery(document).on("click", function (e) {
	 var popup = jQuery(".search-bar");
		if (!jQuery('.search-form').is(e.target) && !popup.is(e.target) && popup.has(e.target).length == 0) {
		   jQuery(".search-form").slideUp();
		}
	});

});

// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const GOOGLE = document.querySelector("#google_translate_element");
const INPUTS = document.querySelectorAll(".language-picker__input");

const INIT = (google_te_combo = null) => {
    INPUTS.forEach((input) => {
        input.addEventListener("change", () => {
            console.log("Language updated");
            if (input.value !== "") {
                google_te_combo.value = input.value;

                if ("createEvent" in document) {
                    const EVT = document.createEvent("HTMLEvents");

                    EVT.initEvent("change", false, true);
                    google_te_combo.dispatchEvent(EVT);
                } else {
                    google_te_combo.fireEvent("onchange");
                }
            }
        });
    });
};

const OBSERVER = new MutationObserver(() => {
    const GOOGLE_TE_COMBO = GOOGLE.querySelector(".goog-te-combo");

    if (GOOGLE_TE_COMBO) {
        INIT(GOOGLE_TE_COMBO);
        OBSERVER.disconnect();
    }
});

OBSERVER.observe(GOOGLE, {
    childList: true,
    subtree: true
});
